import React, { useState } from 'react';
import {
    Modal,
    Typography,
    TextField,
    Box,
    IconButton,
    Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Button from "./buttons/Button";

const ShareDashboardModal = ({ isOpen, onClose, onSendInvitation, instname, apiUrl }) => {
    const [email, setEmail] = useState('');
    const [givenName, setGivenName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [message, setMessage] = useState('');
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);
    const [isInvitationSent, setIsInvitationSent] = useState(false);
    const [emailPreview, setEmailPreview] = useState('');
    const [isError, setIsError] = useState(false);

    const handleEmailChange = (e) => {
        setEmail(e.target.value.toLowerCase());
        setMessage('');
    };

    const handleGivenNameChange = (e) => setGivenName(e.target.value);
    const handleFamilyNameChange = (e) => setFamilyName(e.target.value);
    const handleTitleChange = (e) => setJobTitle(e.target.value);

    const handleEmailCheck = async () => {
        if (!email || !givenName || !familyName) {
            setMessage('Please fill in all required fields.');
            setIsError(true);
            return;
        }

        const validateEmail = (email) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        };

        const normalizedEmail = email.toLowerCase();

        if (!validateEmail(normalizedEmail)) {
            setMessage('Please enter a valid email address.');
            setIsError(true);
            return;
        }

        try {
            const previewResponse = await fetch(`${apiUrl}/getEmailPreview?email=${normalizedEmail}&inst_name=${instname}&given_name=${givenName}&family_name=${familyName}&job_title=${jobTitle}`, {
                method: 'GET',
                credentials: 'include'
            });

            if (previewResponse.ok) {
                const previewContent = await previewResponse.text();
                setEmailPreview(previewContent);
                setIsPreviewVisible(true);
                setIsError(false);
            } else {
                setMessage('Failed to get email preview. There might be an issue with the server.');
                setIsError(true);
            }
        } catch (error) {
            console.error('Error getting email preview:', error);
            setMessage('Error getting email preview');
            setIsError(true);
        }
    };

    const handleSendEmail = async () => {
        try {
            const normalizedEmail = email.toLowerCase();
            const success = await onSendInvitation(normalizedEmail, givenName, familyName, jobTitle);
            if (success) {
                setMessage('Invitation sent successfully.');
                setIsInvitationSent(true);
                setIsError(false);
            } else {
                throw new Error('Failed to send invitation');
            }
        } catch (error) {
            setMessage('Failed to send invitation.');
            setIsInvitationSent(false);
            setIsError(true);
        }
    };


    const handleClose = () => {
        setEmail('');
        setGivenName('');
        setFamilyName('');
        setJobTitle('');
        setMessage('');
        setIsPreviewVisible(false);
        setIsInvitationSent(false);
        onClose();
    };

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '35%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    maxHeight: '80vh',
                    overflowY: 'auto'
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h5" component="h2" gutterBottom>
                    Share Dashboard
                </Typography>
                {!isPreviewVisible ? (
                    <>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            Invite people in your organization to join this private dashboard. When they accept the invitation, they will be able to view and interact with the same statistics and data. An email will be sent to the address provided.
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                            <TextField
                                label="Email"
                                value={email}
                                onChange={handleEmailChange}
                                fullWidth
                                margin="normal"
                                required
                            />
                            <TextField
                                label="Job Title (Optional)"
                                value={jobTitle}
                                onChange={handleTitleChange}
                                margin="normal"
                                fullWidth
                            />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                            <TextField
                                label="Given Name"
                                value={givenName}
                                onChange={handleGivenNameChange}
                                margin="normal"
                                required
                                fullWidth
                            />
                            <TextField
                                label="Family Name"
                                value={familyName}
                                onChange={handleFamilyNameChange}
                                margin="normal"
                                required
                                fullWidth
                            />
                        </Box>
                    </>
                ) : (
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="body1" gutterBottom >
                            An invitation email will be sent with the following details:
                        </Typography>
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <Typography variant="body1" >
                                <strong>From:</strong> membership-dashboard@arxiv.org
                            </Typography>
                            <Typography variant="body1" >
                                <strong>Subject:</strong> A new arXiv membership dashboard was shared with you
                            </Typography>
                            <Typography variant="body1" >
                                <strong>Message:</strong>
                            </Typography>
                            <Box sx={{
                                whiteSpace: 'pre-wrap',
                                mt: 1,
                                p: 2,
                                bgcolor: 'grey.100',
                                borderRadius: 1,
                                fontSize: '0.875rem',
                                lineHeight: 1.43,
                            }}>
                                {emailPreview && JSON.parse(emailPreview).text.replace(/\\n/g, '\n')}
                            </Box>
                        </Box>
                    </Box>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2 }}>
                    <Button onClick={handleClose} text="Cancel" />
                    {!isPreviewVisible ? (
                        <Button onClick={handleEmailCheck} text="Send Invitation" />
                    ) : (
                        <Button 
                            onClick={isInvitationSent ? handleClose : handleSendEmail} 
                            text={isInvitationSent ? "Close" : "Send Email"} 
                        />
                    )}
                </Box>
                {message && (
                    <Typography
                        variant="body2"
                        marginTop={2}
                        textAlign="right"
                        sx={{
                            color: isError ? 'error.main' : 'success.main',
                        }}
                    >
                        {message}
                    </Typography>
                )}
            </Box>
        </Modal>
    );
};

export default ShareDashboardModal;